@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
  margin: 0;
  margin-top: 0 !important;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}
body {
  display: flex;
  flex-direction: column;
  background-color: #eee;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: row;
}
main {
  display: flex;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  flex-direction: column;
  /* padding: 20px; */
  @apply space-x-4;
  @apply space-y-4;
}
main + .submenu {
  padding-left: 0;
}

.ux-sidebar {
  padding-left: 0;
  padding-right: 0;
  background-color: #1b2730;
  transition: transform 100ms ease;
  width: 3.5rem;
  text-align: center;
}

.ux-sidebar .sidebar-top {
  line-height: 3rem;
}
.ux-sidebar .sidebar-top a {
  color: #1b2730;
  @apply dark:text-[#c8beb6];
}
.sidebar-main ul {
  padding: 0;
  margin: 0;
}
.sidebar-main ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ux-sidebar .nav-link {
  color: #c8beb6;
}
.ux-sidebar .nav-link.active {
  background-color: rgb(50, 74, 91);
}
.card {
  @apply bg-white;
  /* @apply dark:bg-slate-900; */
  @apply dark:bg-[#242424];
  @apply dark:text-white;
  @apply text-slate-900;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  @apply m-4;
  @apply p-4;
  @apply border-opacity-90;
  @apply border-black;
}

.small, small {
  font-size: 85%;
}
